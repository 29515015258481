import React, {useState, useEffect, useContext, useRef} from 'react'
import { Grid, Typography, Tooltip } from '@mui/material';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { useCalendarStyles } from '@styles/fullCalendar.style'
import { transformText } from '@helpers/common'
import DynamicIcon from '@components/DynamicIcon';
import { AuthContext } from '@context/auth/AuthContext'
import { snackActions } from "@helpers/snackbarUtils";
import { addNewEvent, updateEvent } from '../../../services/schedulerServicesHelper'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import RedoIcon from '@mui/icons-material/Redo'

const Calendar = ({
  events,
  resources,
  eventSelected,
  setEventSelected,
  setOpenEventDetails,
  setOpenLoading,
  setOpenEventEditeVerification,
  currentMonth,
  setCurrentMonth,
  setCurrentYear,
  currentDate,
  idUserSelected,
  idEventSelected,
  setIdEventSelected,
  setEventDateFrom,
  eventsOverlapByDay
}) => {
  const classes = useCalendarStyles()
  const { user } = useContext(AuthContext)
  const today = new Date();
  const realMonth = today.getMonth() + 1
  const formattedMonthToday = realMonth < 10 ? `0${realMonth}` : realMonth;
  const dayOfMonth = today.getDate();
  const formattedDayToday = dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth;
  const [ hasChanged, setHasChanged ] = useState(false)
  const [ newDateTo, setNewDateTo ] = useState('')
  const [ newTimeTo, setNewTimeTo ] = useState('')
  const [ newTimeFrom, setNewTimeFrom ] = useState('')
  const [ newResource, setNewResource ] = useState('')
  const [ newEventDateFrom, setNewEventDateFrom ] = useState('')
  const [ newEventDateTo, setNewEventDateTo ] = useState('')
  const eventToClone = JSON.parse(sessionStorage.getItem('eventToClone'))
  const { t } = useTranslation()
  const calendarRef = useRef(null);

  // Botón para restablecer las notificaciones de los cumpleaños
  const clearBirthdayNotifications = () => {
    Object.keys(sessionStorage).forEach(key => {
      if (key.startsWith('birthday_')) {
        console.log(`Eliminando clave: ${key}`);
        sessionStorage.removeItem(key);
      }
    });
    snackActions.success('Las notificaciones de cumpleaños han sido restablecidas.');
  };

   
  useEffect(() => {
    const resourceCells = document.querySelectorAll('.fc-resource .fc-datagrid-cell-main');
    const resourceLine = document.querySelectorAll('.fc-timeline-lane');
    const allLines = document.querySelectorAll('.fc-resource .fc-timeline-lane-frame');
   
    const numberOfResources = []
    const birthdayEvents = events?.filter(event => event?.idCategory === 25)
    const lineHeight = 26.5

      
  if (events.length > 0) {

    resourceCells.forEach((cell) => {
      const resourceTitle = cell.textContent;
      //NOTA: se debe comparar con == en lugar de === porque si no no funciona bien.
      if (resourceTitle.includes(formattedDayToday) && currentMonth == formattedMonthToday) {
        cell.parentElement.parentElement.style.backgroundColor = '#337AB7';
        cell.parentElement.style.backgroundColor = '#337AB7';
      }
      if (resourceTitle.includes('S') || resourceTitle.includes('D')) {
        const number = resourceTitle.substring(0, 2);
        numberOfResources.push(number);
        
        cell.parentElement.parentElement.style.backgroundColor = '#EFEFEF';
        cell.parentElement.style.backgroundColor = '#EFEFEF';
      }
      if (eventsOverlapByDay[resourceTitle.substring(0, 2)] !== undefined) {
        cell.parentElement.parentElement.style.height = `${lineHeight * eventsOverlapByDay[resourceTitle.substring(0, 2)]}px`;
        cell.parentElement.parentElement.style.maxHeight = `${lineHeight * eventsOverlapByDay[resourceTitle.substring(0, 2)]}px`;
      }
    });

    resourceLine.forEach((line) => {
      const resourceTitle = line.getAttribute('data-resource-id');
      
      if (numberOfResources.includes(resourceTitle)) {
        line.parentElement.style.backgroundColor = '#EFEFEF';
      }
    });

    allLines.forEach((line, i) => {
      line.removeAttribute('style');
      const index = ('0' + (i + 1)).toString().slice(-2)
    
      if (eventsOverlapByDay[index] !== undefined) {
        line.style.height = `${lineHeight * eventsOverlapByDay[index]}px`;
        line.style.maxHeight = `${lineHeight * eventsOverlapByDay[index]}px`;
      }
    })

    // Lógica para mostrar notificación de cumpleaños
    if (birthdayEvents?.length > 0) {
      resourceCells.forEach((cell) => {
        const resourceTitle = cell.textContent;

        birthdayEvents.forEach((event) => {
          if (resourceTitle.includes(event.resourceId)) {
            const birthdayKey = `birthday_${event.resourceId}_${event.eventTitle}`;

            cell.style.textDecoration = 'underline';
            cell.style.color = 'red';
            cell.style.cursor = 'pointer';

            // Agregar evento de clic para mostrar notificación
            cell.addEventListener('click', () => {
              snackActions.info('Es el cumpleaños de ' + event.eventTitle)
            })

            // Mostrar la notificación solo si no ha sido mostrada antes
            if (formattedDayToday == event.resourceId && !sessionStorage.getItem(birthdayKey)) {
              snackActions.info('Es el cumpleaños de ' + event.eventTitle);
              sessionStorage.setItem(birthdayKey, 'shown');
            }
          }
        });
      });
    }
  }
  }, []);


  useEffect(() => {
    if (eventSelected !== null && idEventSelected !== null && events.length > 0 && (newDateTo !== '' || newResource !== '' || newTimeFrom !== '' || newTimeTo !== '')) {
      let eventSelectedData = events.find((event) => event.idSchedulerEvent == idEventSelected)
      const dateFrom = new Date(newEventDateFrom);
      const dateTo = new Date(newEventDateTo);
      const year = dateFrom.getUTCFullYear();
      const month = ("0" + (dateFrom.getUTCMonth() + 1)).slice(-2);
      const day = ("0" + dateFrom.getUTCDate()).slice(-2);

      if (newDateTo !== '') { // RESIZE
        eventSelectedData.eventDateTo = newDateTo

        setEventSelected(eventSelectedData);
      }
      if (newResource !== '') { // DROP. RECURSO/DÍA        
        // From
        const hours = ("0" + dateFrom.getUTCHours()).slice(-2);
        const minutes = ("0" + dateFrom.getUTCMinutes()).slice(-2);
        
        const newDateUTCF = new Date(Date.UTC(year, parseInt(month) -1, parseInt(newResource), parseInt(hours), parseInt(minutes) ));
        const hoursUTCF = ("0" + newDateUTCF.getHours()).slice(-2);
        const newDateToDataF = `${year}-${month}-${newResource}T${hoursUTCF}:${minutes}:00`;
        
        // To
        const hourst = ("0" + dateTo.getUTCHours()).slice(-2);
        const minutest = ("0" + dateTo.getUTCMinutes()).slice(-2);

        const newDateUTCT = new Date(Date.UTC(year, parseInt(month) -1, parseInt(newResource), parseInt(hourst), parseInt(minutest) ));
        const hoursUTCT = ("0" + newDateUTCT.getHours()).slice(-2);
        const newDateToDataT = `${year}-${month}-${newResource}T${hoursUTCT}:${minutes}:00`;

        eventSelectedData.eventDateFrom = newDateToDataF
        eventSelectedData.eventDateTo = newDateToDataT

        setEventSelected(eventSelectedData);
      }
      if (newTimeFrom !== '') {
        const newDateUTCF = new Date(Date.UTC(year, parseInt(month) -1, parseInt(day), parseInt(newTimeFrom.hours), parseInt(newTimeFrom.minutes) ));
        const hoursUTCF = ("0" + newDateUTCF.getHours()).slice(-2);

        const newdateFromData = newResource === '' 
          ? `${year}-${month}-${day}T${hoursUTCF}:${newTimeFrom.minutes}:00`
          : `${year}-${month}-${newResource}T${hoursUTCF}:${newTimeFrom.minutes}:00`;
        
        eventSelectedData.eventDateFrom = newdateFromData

        setEventSelected(eventSelectedData);
      }
      if (newTimeTo !== '') {
        const newDateUTCT = new Date(Date.UTC(year, parseInt(month) -1, parseInt(day), parseInt(newTimeTo.hours), parseInt(newTimeTo.minutes) ));
        const hoursUTCT = ("0" + newDateUTCT.getHours()).slice(-2);

        const newdateToData = newResource === '' 
          ? `${year}-${month}-${day}T${hoursUTCT}:${newTimeTo.minutes}:00`
          : `${year}-${month}-${newResource}T${hoursUTCT}:${newTimeTo.minutes}:00`;
        
        eventSelectedData.eventDateTo = newdateToData

        setEventSelected(eventSelectedData);
      }
      
      setOpenEventEditeVerification(true)
    }
  }, [newTimeTo, newTimeFrom, newResource, hasChanged])
      
  const handleClickDateEvent = (e) => {
    const newEventDate = new Date(e.date) 
    const newEventResource = e.resource._resource.id
    const formattedDateFrom = 
    `${newEventDate.getFullYear()}-${(newEventDate.getMonth() + 1).toString().padStart(2, "0")}-${newEventDate.getDate().toString().padStart(2, "0")}T${newEventDate.getHours().toString().padStart(2, "0")}:${newEventDate.getMinutes().toString().padStart(2, "0")}:${newEventDate.getSeconds().toString().padStart(2, "0")}`;
    const formattedDateTo = 
    `${newEventDate.getFullYear()}-${(newEventDate.getMonth() + 1).toString().padStart(2, "0")}-${newEventDate.getDate().toString().padStart(2, "0")}T${(newEventDate.getHours() + 1).toString().padStart(2, "0")}:${newEventDate.getMinutes().toString().padStart(2, "0")}:${newEventDate.getSeconds().toString().padStart(2, "0")}`;
    const newEventRealDateFrom = 
    `${newEventDate.getFullYear()}-${(newEventDate.getMonth() + 1).toString().padStart(2, "0")}-${newEventResource}T${newEventDate.getHours().toString().padStart(2, "0")}:${newEventDate.getMinutes().toString().padStart(2, "0")}:${newEventDate.getSeconds().toString().padStart(2, "0")}`;
    const newEventRealDateTo =
    `${newEventDate.getFullYear()}-${(newEventDate.getMonth() + 1).toString().padStart(2, "0")}-${newEventResource}T${(newEventDate.getHours() + 1).toString().padStart(2, "0")}:${newEventDate.getMinutes().toString().padStart(2, "0")}:${newEventDate.getSeconds().toString().padStart(2, "0")}`;
    const hours = newEventDate.getHours();
    const minutes = newEventDate.getMinutes().toString().padStart(2, "0");

    if (eventToClone !== null) {
      const dateFrom = moment(eventToClone.eventDateFrom);
      const dateTo = moment(eventToClone.eventDateTo);

      const hoursDiff = dateTo.diff(dateFrom, 'hours');

      const formattedNewDateTo =
      `${newEventDate.getFullYear()}-${(newEventDate.getMonth() + 1).toString().padStart(2, "0")}-${newEventResource}T${(newEventDate.getHours() + hoursDiff).toString().padStart(2, "0")}:${newEventDate.getMinutes().toString().padStart(2, "0")}:${newEventDate.getSeconds().toString().padStart(2, "0")}`;

      eventToClone.eventDateFrom = newEventRealDateFrom
      eventToClone.eventDateTo = formattedNewDateTo
      
      addNewEvent(eventToClone, setOpenLoading, setOpenEventDetails, setEventSelected)
      sessionStorage.removeItem('eventToClone')
    } else {
      setEventSelected({
        eventDateFrom: newEventRealDateFrom,
        eventDateTo: newEventRealDateTo,
        resourceId: newEventResource,
        start: formattedDateFrom,
        end: formattedDateTo,
        timeText: hours+' '+minutes 
      })
      setIdEventSelected(null)
      sessionStorage.setItem('idEventSelected', null)
  
      setOpenEventDetails(true)
    }
  }

  const handleDropEvent = (e) => {
    setNewEventDateFrom(e.event._def.extendedProps.eventDateFrom)
    setNewEventDateTo(e.event._def.extendedProps.eventDateTo)
    
    if (e.newResource !== null) {
      const newResourceData = e.newResource.id

      setNewResource(newResourceData)
      setHasChanged(true)
    }

    if ( e.delta.milliseconds !== 0 ) {
      const dateFrom = new Date(e.oldEvent._def.extendedProps.eventDateFrom);
      const dateTo = new Date(e.oldEvent._def.extendedProps.eventDateTo);

      const millisecondsDif = e.delta.milliseconds;

      // FROM
      let dateObj1 = new Date(dateFrom.getTime() + millisecondsDif);

      const hours1 = ("0" + dateObj1.getUTCHours()).slice(-2);
      const minutes1 = ("0" + dateObj1.getUTCMinutes()).slice(-2);

      // TO
      let dateObj = new Date(dateTo.getTime() + millisecondsDif);

      const hours = ("0" + dateObj.getUTCHours()).slice(-2);
      const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);
      
      setNewTimeFrom({
        hours: hours1,
        minutes: minutes1
      })
      setNewTimeTo({
        hours: hours,
        minutes: minutes
      })

      setHasChanged(true)
    }
  }

  const handleResizeEvent = (e) => {
    setIdEventSelected(e.event._def.extendedProps.idSchedulerEvent)
    sessionStorage.setItem('idEventSelected', e.event._def.extendedProps.idSchedulerEvent)

    const dateTo = new Date(e.oldEvent._def.extendedProps.eventDateTo);
    const millisecondsDif = e.endDelta.milliseconds;

    let dateObj = new Date(dateTo.getTime() + millisecondsDif);

    const year = dateObj.getUTCFullYear();
    const month = ("0" + (dateObj.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getUTCDate()).slice(-2);
    const hours = ("0" + dateObj.getUTCHours()).slice(-2);
    const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);

    const newDateUTC = new Date(Date.UTC(year, parseInt(month) -1, parseInt(day), parseInt(hours), parseInt(minutes) ));
    const hoursUTC = ("0" + newDateUTC.getHours()).slice(-2);
    const newDateToData = `${year}-${month}-${day}T${hoursUTC}:${minutes}:00`;
    
    setNewDateTo(newDateToData)

    setHasChanged(true)
  }

  const renderEventContent = (eventInfo) => {
    const idCategory = eventInfo.event._def.extendedProps.idCategory;
    const dateFrom = new Date(eventInfo.event._def.extendedProps.eventDateFrom);
    const dateTo = new Date(eventInfo.event._def.extendedProps.eventDateTo);
    const milisecondsDif = dateTo - dateFrom;
    const hoursDif = milisecondsDif / (1000 * 60 * 60);

    const { eventPrivate, idUsers } = eventInfo.event._def.extendedProps;
    const isPrivate = eventPrivate;
    const isOwn = idUsers.includes(user.idUser);

      // Verifica si la categoría del evento es 25 (Cumpleaños), y de ser así, no renderiza el contenido
    if (idCategory === 25) {
      return false;
    }
  
      // Tooltip basado en si el evento es privado y si el usuario es el dueño, o no
      // Las categorias PRIVADO (12) y BLOQUEADO (22) no muestran el título del evento y en cada una coge su correspondiente traducción
      const textTooltipPrivate = (isPrivate && idCategory === 22) ? t('SCHEDULER.CATEGORY_BLOCKED').toUpperCase() : t('SCHEDULER.PRIVATE').toUpperCase();
      const tooltipTitle = isPrivate && !isOwn ? textTooltipPrivate : (
      <div>
        <div dangerouslySetInnerHTML={{ __html: eventInfo?.event._def.extendedProps.eventTitle }} />
        {eventInfo?.event._def.extendedProps.eventInfo && (
          <div style={{ marginTop: '3px' }}> {/*Separación entre eventTitle y eventInfo en el Tooltip*/}
            <div dangerouslySetInnerHTML={{ __html: eventInfo.event._def.extendedProps.eventInfo }} />
          </div>
        )}
      </div>
    );
  
    // Formateo de las horas
    const formatTimeText = (timeText) => {
      const [hours, minutes] = timeText.split(':');
      return parseInt(hours) === 0 ? `0:${minutes}` : `${parseInt(hours, 10)}:${minutes}`;
    };
  
    return (
      <Tooltip title={tooltipTitle}>
        <div style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          color: eventInfo?.event._def.extendedProps.eventConfirmed ? eventInfo?.event._def.extendedProps.eventFontColor : 'black',
          maxHeight: '24px',
          height: '24px',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          backgroundColor: eventInfo?.event._def.extendedProps.eventConfirmed ? eventInfo?.event._def.extendedProps.eventColor : '#ECECEC'
        }}>
  
            {/*No sale la barra cuando no hay EventInfo o se ha borrado desde la agenda*/}
            {eventInfo?.event._def.extendedProps.eventInfo?.trim() ? (
              <div style={{
                height: '20px',
                width: '3px',
                minWidth: '3px',
                backgroundColor: 'black', //Barra negra en eventos con + info
                marginRight: '1px',
                flex: '0 0 auto'
              }}>
              </div>
            ) : null}
  
            {eventInfo?.event._def.extendedProps.eventTimePreBlocked !== 0 && (() => {
              const nbpr = eventInfo?.event._def.extendedProps.eventTimePreBlocked / 30.00;
              const wpr = (30 * nbpr);
              const iwpr = parseInt(wpr, 10) - 2;
  
              return (
                <div style={{
                  order: '1',
                  width: `${iwpr}px`,
                  textAlign: 'center',
                  flex: '0 0 auto',
                  zIndex: '999',
                  padding: '0',
                  color: 'black',
                  backgroundColor: '#ccc'
                }}>
                  <small>{eventInfo?.event._def.extendedProps.eventTimePreBlocked}m</small>
                </div>
              )
            })()}
  
            {eventInfo?.event._def.extendedProps.eventTimePostBlocked !== 0 && (() => {
              const nbpr = eventInfo?.event._def.extendedProps.eventTimePostBlocked / 30.00;
              const wpr = (30 * nbpr);
              const iwpr = parseInt(wpr, 10);
  
              return (
                <div style={{
                  order: '3',
                  color: 'black',
                  width: `${iwpr}px`,
                  minWidth: `${iwpr}px`,
                  flexShrink: '0 !important',
                  textAlign: 'center',
                  zIndex: '999',
                  padding: '0',
                  float: 'right !important',
                  backgroundColor: '#ccc',
                  marginLeft: 'auto'
                }}>
                  <small>{eventInfo?.event._def.extendedProps.eventTimePostBlocked}m</small>
                </div>
              )
            })()}
  
            <div style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              flex: '1 1 auto',
              order: '2',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
  
              {hoursDif > 2 ? ( // Para eventos que duran más de 2 horas
                <Typography
                  component={'div'}
                  ml={0.2}
                  sx={{
                    fontSize: '1em',
                    fontFamily: 'Arial Nova',
                    fontWeight: 'bold !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '1.1em',
                    whiteSpace: 'nowrap',
                    minWidth: '36px', //Hace que las horas no queden cortadas
                    maxWidth: '36px', //Hace que las horas no queden cortadas
                    marginLeft: '0px'
                  }}
                >
                  {formatTimeText(eventInfo?.event._def.extendedProps.timeText)}
                </Typography>
              
              ) : ( // Para eventos que duran 2 horas o menos
  
                <div style={{ textAlign: 'center', margin: '0' }}>
                  <Typography
                    component={'div'}
                    sx={{
                      fontSize: '0.8em',
                      fontFamily: 'Arial Nova',
                      fontWeight: 'bold !important',
                      overflow: 'hidden',
                      lineHeight: '1.1em',
                      whiteSpace: 'nowrap',
                      overflowWrap: 'normal',
                      minWidth: '16px', //Hace que las horas no queden cortadas
                      maxWidth: '16px', //Hace que las horas no queden cortadas
                      marginLeft: '-2px',
                      textAlign: 'right'
                    }}
                  >
                    {parseInt(eventInfo?.event._def.extendedProps.timeText.split(':')[0], 10)}
                  </Typography>
                  <Typography
                    component={'div'}
                    sx={{
                      fontSize: '0.8em',
                      fontFamily: 'Arial Nova',
                      fontWeight: 'bold !important',
                      lineHeight: '1.1em',
                      marginLeft: '-2px',
                      textAlign: 'right'
                    }}
                  >
                    {eventInfo?.event._def.extendedProps.timeText.split(':')[1]}
                  </Typography>
                </div>
              )}
  
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                whiteSpace: hoursDif > 2 ? 'nowrap' : 'normal',
                overflow: 'hidden',
                flex: '1 1 auto',
                flexDirection: hoursDif > 2 ? 'row' : 'column',
                flexWrap: hoursDif > 2 ? 'nowrap' : 'wrap',
                marginLeft: '2px'
              }}>
                
                {/* Información del Evento con icono */}
                {eventInfo?.event._def.extendedProps.icon && (
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start', 
                    maxWidth: '100%', 
                    overflow: 'hidden', 
                    whiteSpace: 'normal' 
                  }}>
                    
                    {hoursDif <= 2 ? (
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        overflow: 'hidden',
                      }}>
                        <div style={{
                          flex: '0 0 auto',
                          marginRight: '4px',
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          <DynamicIcon
                            iconName={eventInfo?.event._def.extendedProps.icon}
                            style={{
                              fontSize: '10px',
                            }}
                          />
                        </div>
                        <Typography
                          component={'div'}
                          sx={{
                            fontSize: '0.8em',
                            fontFamily: 'Arial Nova',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            maxWidth: 'calc(100% - 14px)',
                            alignItems: 'flex-start',
                          }}
                                        >
                                            <span style={{ fontWeight: 'bold !important' }} dangerouslySetInnerHTML={{ __html: (isPrivate && !isOwn) ? textTooltipPrivate : transformText(eventInfo?.event._def.extendedProps.eventTitle) }} />
                        </Typography>
                      </div>
                    ) : (
                    
                      // Icono y título en la misma fila
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        width: '100%',
                        overflow: 'hidden',
                      }}>
                        <DynamicIcon
                          iconName={eventInfo?.event._def.extendedProps.icon}
                          style={{
                            fontSize: '15px',
                            marginRight: '4px',
                          }}
                        />
                        <Typography
                          component={'span'}
                          sx={{
                            fontSize: '1em',
                            fontFamily: 'Arial Nova',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            maxWidth: '100%',
                            flex: 'none',
                          }}
                        >
                                                <span dangerouslySetInnerHTML={{ __html: (isPrivate && !isOwn) ? textTooltipPrivate : transformText(eventInfo?.event._def.extendedProps.eventTitle) }} />
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
  
                {/* Información del Evento sin icono */}
                {!eventInfo?.event._def.extendedProps.icon && (
                  <Typography
                    component={'div'}
                    sx={{
                      fontSize: hoursDif > 2 ? '1em' : '0.8em',
                      fontFamily: 'Arial Nova',
                      fontWeight: 'bold',
                      whiteSpace: hoursDif > 2 ? 'nowrap' : 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: hoursDif > 2 ? 'block' : '-webkit-box',
                      WebkitLineClamp: hoursDif <= 2 ? 2 : 'none',
                      WebkitBoxOrient: hoursDif <= 2 ? 'vertical' : 'initial',
                      lineHeight: '1em'
                    }}
                  >
                      {/* Título "PRIVADO" si se cumplen las dos condiciones */}
                      <span style={{ whiteSpace: hoursDif > 2 ? 'nowrap' : 'normal' }} 
                        dangerouslySetInnerHTML={{ __html: (isPrivate && !isOwn) ? textTooltipPrivate : transformText(eventInfo?.event._def.extendedProps.eventTitle) }} />
                    </Typography>
                )}
              
            </div>
          </div>
        </div>
      </Tooltip>
    )
  }
  
  
    
  return (
    <Grid container spacing={0} className="dx-viewport" sx={{display:'flex', justifyContent:'center'}} mt={2.5}>
      <Grid item xs={12} md={10} className={classes.fullCalendar} mb={5}>

        <FullCalendar
              ref={calendarRef}
              locale='es'
              schedulerLicenseKey= 'CC-Attribution-NonCommercial-NoDerivatives'
              // themeSystem='standard'
              themeSystem="bootstrap"
              height= 'auto'
              aspectRatio= {1}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin, interactionPlugin]}
              initialView='resourceTimelineDay'
              slotMinTime="06:00:00" 
              slotMaxTime="24:00:00"
              // footerToolbar={true}
              weekends={true}
              events={events}
              eventContent={renderEventContent}
              eventClick={(e)=> {
                setIdEventSelected(e.event._def.extendedProps.idSchedulerEvent)
                setEventDateFrom(e.event._def.extendedProps.eventDateFrom)
                                
                let eventSelectedData = events?.find((event) => event.idSchedulerEvent == e.event._def.extendedProps.idSchedulerEvent && event.eventDateFrom ==  e.event._def.extendedProps.eventDateFrom )
                setEventSelected(eventSelectedData)
                sessionStorage.setItem('idEventSelected', e.event._def.extendedProps.idSchedulerEvent)
                sessionStorage.setItem('eventDateFrom', e.event._def.extendedProps.eventDateFrom)
                const isPrivate = e.event._def.extendedProps.eventPrivate
                const idUsers = e.event._def.extendedProps.idUsers
                const isOwn = idUsers.includes(user.idUser)             
       
                if (isPrivate && !isOwn) {
                  setOpenEventDetails(false)
                } else {
                  setTimeout(() => {
                    setOpenEventDetails(true)
                  }, 500);
                }
              }}              
              eventDisplay={'liquid'}
              headerToolbar={{
                  left: '',
                  center: 'prev,title,next',
                  right: ''
              }}
              buttonText={{
                prev: '<',
                next: '>',
              }}
              buttonHints={{
                prev: '',
                next: '',
              }}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              resourceAreaHeaderContent="Días"
              resourceAreaWidth= '39px'
              resources={resources}            
              slotLabelFormat={{
                  hour12: false,
                  hour: 'numeric',
                  omitZeroMinute: true,
                  meridiem: 'short',
              }}
              titleFormat={(info) => moment(info.date).format('MMMM YYYY')}
              datesSet={(dates) => {
                // Manejar aquí el evento datesSet
                console.log('datesSet:', dates);
                
                setCurrentMonth(("0" + (dates.start.getMonth() + 1)).slice(-2));
                setCurrentYear(dates.start.getFullYear())
              }}
              initialDate= {currentDate}
              dateIncrement={{month: 1}}
              dateClick={(e) => handleClickDateEvent(e)}
              eventDrop={(e) => handleDropEvent(e)}
              eventDragStart={(e) => {
                setIdEventSelected(e.event._def.extendedProps.idSchedulerEvent)
                sessionStorage.setItem('idEventSelected', e.event._def.extendedProps.idSchedulerEvent)
              }}
              eventResize={(e) => handleResizeEvent(e)}
              eventResizableFromStart={false}
              stickyFooterScrollbar={true}
              eventResizeStart={(e) => {
                let eventSelectedData = events?.find((event) => event.idSchedulerEvent == e.event._def.extendedProps.idSchedulerEvent)
                setEventSelected(eventSelectedData)
              }}
              />

              <IconButton 
                style={{ display: 'block', margin: '10px auto' }} 
                onClick={clearBirthdayNotifications}
              >
                <RedoIcon />
              </IconButton>

        </Grid> 
    </Grid>
  )
};


export default Calendar;
