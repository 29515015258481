import React, { useState, useEffect } from 'react'
import {Grid, Tooltip, IconButton, Box, Button, TextField, Typography, Card} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import DynamicTable from '@components/DynamicTable';
import { useParams } from 'react-router-dom';
import serviceIncidents from '@services/serviceIncidents'
import serviceCycles from '@services/serviceCycles';
import Dialog from '@components/Dialog'
import {snackActions} from '@helpers/snackbarUtils';
import { useStyles } from '@styles/consultants.style'
import Loading from '@components/Loading'

const CycleIncidents = () => {
  const { idCycle, idCycleJourney } = useParams();
  const [openDialog, setOpenDialog] = useState(false)
  // const [formData, setFormData] = useState({
  //   descriptionIncident: ''
  // });
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [incidences, setIncidences] = useState([]);
  const [incidenceSelected, setIncidenceSelected] = useState(null);
  const [cycleJourneysIdList, setCycleJourneysIdList] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(0);
  const classes = useStyles()
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true); // Estado de loading
  const [cycleDetailsLoading, setCycleDetailsLoading] = useState(true);

  // const area = sessionStorage.getItem('area');
  // let ocultar = false;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchIncidences();   // Cargar incidencias
        await fetchCycleDetails(idCycle);  // Cargar detalles del ciclo
      } finally {
        setLoading(false);  // Desactivar el estado de loading solo después de cargar incidencias y detalles del ciclo
      }
    };
    fetchData();
  }, [idCycle]); // Corre ambos efectos solo cuando idCycle cambie

  useEffect(() => {
    fetchCycleDetails(idCycle);
  }, []);

  const fetchIncidences = async() => {
    const { data, error } = await serviceIncidents.getIncidentsList({
      pageSize: 1000,
      pageNumber: 0,
      globalSearch: '',
      search: [
        {
            searchField: 'idCycle',
            searchMode: 'equalTo',
            searchValue: idCycle
        },
        {
            SearchField: 'isOtherCompanyIncident',
            SearchMode: 'EqualTo',
            SearchValue: 0,
        }
      ],
      order: [
        {
            orderField: 'dateIncident',
            orderAsc: false
        }
      ]
  });
   
    if (!error) {
      setIncidences(data);
    }
  }

  const fetchCycleDetails = async(idCycle) => {
    const { data, error } = await serviceCycles.getCycleDetail(idCycle, true);
    if (!error) {
      setCycleJourneysIdList(data.cycleJourneys.map(journey => journey.idCycleJourney));
    }
    setCycleDetailsLoading(false); // Indicador de que los detalles del ciclo ya se han cargado
  };

  const getNumberOfJourney = (idCycleJourney) => {
    const index = cycleJourneysIdList.findIndex(id => id === idCycleJourney);
    return index !== -1 ? index + 1 : -1;
  }

  const handleChange = (e) => {
    setIncidenceSelected({
        ...incidenceSelected,
        [e.target.name]: e.target.value
    });
  };

  // const columnSearch = [{
  //   SearchField: 'IdCycle',
  //   SearchValue: idCycle,
  //   SearchMode: 'EqualTo'
  // },
  // {
  //   SearchField: 'IdCycleJourney',
  //   SearchValue: idCycleJourney,
  //   SearchMode: 'EqualTo'
  // }];

  // const handleShowIncidentsDetail = idIncident => {
  //   serviceIncidents.getIncidentsDetail(idIncident).then(response => {
  //       if (!response.error) {
  //           // Actualiza el estado description con la descripción obtenida
  //           setFormData(response.data);
  //           setOpenDialog(true)
  //       }
  //   })
  // }

  const handleSubmit = type => {
    switch (type) {
        case 'UPDATE':
            serviceIncidents.updateIncident(incidenceSelected).then(handleResponse)
            break
        default:
            break
    }
  }

  const handleResponse = response => {
    if (!response.error) {
        setOpenDialog(false)
        handleForceUpdate();
        fetchIncidences();
    }
  }

  const handleForceUpdate = () => {
    setForceUpdate(prev => prev + 1);
  };

  // if (area === 'consultant') {
  //   ocultar = true;
  // }

  const deleteIncident = async () => {
    const incidentId = incidenceSelected.idIncident;

    if (!incidentId) {
        snackActions.error(t("INCIDENT.DELETE_ERROR_ID_NULL"));
        return;
    }

    try {
        const response = await serviceIncidents.deleteIncident({ idIncident: incidentId });

        if (!response.error) {
            setOpenDeleteConfirmDialog(false);
            setOpenDialog(false);
            handleForceUpdate();
            fetchIncidences();
        } else {
            snackActions.error(t("INCIDENT.DELETE_ERROR"));
        }
    } catch (error) {
        snackActions.error(t("INCIDENT.DELETE_ERROR"));
        console.error("Error deleting incident:", error);
    }
  };

  const handleIncidence = (idIncident) => {
    const incidenceClicked = incidences.filter(incidence => incidence.idIncident === idIncident);
    if (incidenceClicked !== undefined && incidenceClicked.length > 0) {
      setIncidenceSelected(incidenceClicked[0]);
      setOpenDialog(true);
    }
  };

  // const ORDER = [
  //   {
  //     "orderField": "dateIncident",
  //     "orderAsc": false
  //   }
  // ];
  // const COLUMNS = [
  //   {
  //       accessor: '',
  //       Header: 'Info',
  //       filterable: false,
  //       hideHeader: true,
  //       width: '1%',
  //       Cell: ({ row: { values } }) => (
  //         <Tooltip title={t('INCIDENT.EDIT_INCIDENT')}>
  //             <IconButton
  //                 aria-label='info'
  //                 color='info'
  //                 style={{
  //                     padding: '0px'
  //                 }}
  //                 onClick={() => {
  //                     handleShowIncidentsDetail(values.idIncident)
  //                 }}
  //             >
  //                 <EditRounded fontSize='inherit' />
  //             </IconButton>
  //         </Tooltip>
  //       )
  //   },
  //   {
  //     accessor: 'idCycle',
  //     Header: t('LABEL.ID'),
  //     filterable: false,
  //     hiddenColumn: true,
  //   },
  //   {
  //     accessor: 'idCycleJourney',
  //     Header: t('LABEL.ID_JOURNEY'),
  //     filterable: false,
  //     hiddenColumn: true,
  //   },
  //   {
  //     accessor: 'idIncident',
  //     Header: t('LABEL.ID'),
  //     Cell: props => <Typography variant='body2'>{props.value}</Typography>,
  //     hiddenColumn: ocultar,
  //   },
  //   {
  //     accessor: 'dateIncident',
  //     Header: t('LABEL.DATE'),
  //     Cell: props => <Typography variant='body2'>{moment(props.value).format('DD-MM-YYYY')}</Typography>,
  //     width: '16%',
  //   },
  //   {
  //     accessor: 'cycleName',
  //     Header: t('CYCLES.CYCLE_NAME'),
  //     Cell: props => <Typography variant='body2'>{props.value}</Typography>,
  //     hiddenColumn: ocultar,
  //   },
  //   {
  //     accessor: 'participantName',
  //     Header: t('LABEL.NAME'),
  //     Cell: props => <Typography variant='body2'>{props.value}</Typography>,
  //   },
  //   {
  //     accessor: 'participantSurname',
  //     Header: t('LABEL.SURNAME'),
  //     Cell: props => <Typography variant='body2'>{props.value}</Typography>,
  //   },

  //   {
  //     accessor: 'descriptionIncident',
  //     Header: t('LABEL.DESCRIPTION'),
  //           Cell: props => <Typography variant='body2'>{props.value}</Typography>,
  //           width: '60%',
  //   },

  // ];

  return (
    <div className={classes.outletZoneConsultores} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <Dialog
            open={openDialog}
            width={500}
            hasCloseBtn={true}
            title={t('INCIDENT.EDIT_INCIDENT')}
            close={() => setOpenDialog(false)}
            content={
                <TextField
                    multiline
                    fullWidth
                    name='descriptionIncident'
                    size='small'
                    rows={5}
                    inputProps={{maxLength: 255}}
                    value={incidenceSelected?.descriptionIncident || ''}
                    onChange={handleChange}
                    //helperText={incidenceSelected?.descriptionIncident.length < 10 ? t('INCIDENT.DESCRIPTION.LENGHT') : ''}
                />
            }
            actions={
                <Box display='flex' alignItems='center'>
                    {incidenceSelected?.idIncident && (
                      <Button
                          variant='contained'
                          onClick={() => setOpenDeleteConfirmDialog(true)}
                          color='error'
                          sx={{marginRight: '10px'}}
                      >
                          {t('BUTTON.DELETE')}
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      onClick={() => handleSubmit('UPDATE')}
                      //disabled={incidenceSelected?.descriptionIncident.length < 10} // Deshabilita si la longitud es menor a 10
                    >
                      {t('BUTTON.SAVE')}
                    </Button>
                </Box>
            }
        />

        <Dialog
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            title={t('CONFIRM.TITLE_INCIDENT')}
            //subtitle={t('CONFIRM.UNRECOVERY_DELETE2')}
            hasCloseBtn={false}
            actions={
                <Box display='flex' alignItems='center'>
                    <Box mr={1}>
                        <Button
                            variant='contained'
                            onClick={() => setOpenDeleteConfirmDialog(false)}
                            color='error'
                        >
                            {t('BUTTON.CANCEL')}
                        </Button>
                    </Box>
                    <Button
                        variant='contained'
                        onClick={deleteIncident} // Función que maneja la eliminación
                    >
                        {t('BUTTON.DELETE')}
                    </Button>
                </Box>
            }
        >
            {t('CONFIRM.UNRECOVERY_DELETE')}
        </Dialog>

      <Grid container spacing={1} padding={1}>
        <Grid item xs={1} display="flex" justifyContent="flex-start" alignItems={'center'}>
          <Tooltip title={t('LABEL.CYCLE')}>
            <Link to={"/journey/" + idCycle}><ArrowBack /></Link>
          </Tooltip>
        </Grid>
        <Grid item xs={9.5} md={10} display="flex" justifyContent="center" alignItems={'center'}>
          <h3>{t('TEXT.INCIDENT_CYCLE')}</h3>
        </Grid>
        <Grid item xs={1.5} md={1} display="flex" justifyContent="flex-end" alignItems={'center'}>
          <Tooltip title={t('TEXT.NEW_INCIDENT_CYCLE')}>
            <Link to={"/cyclenewincident/" + idCycle}><AddCircleIcon sx={{color: 'primary.main', fontSize: '2.5em'}}/></Link>
          </Tooltip>
        </Grid>
      </Grid>
      <br />

      {loading ? (
      // Pantalla de carga
      <Loading />
      ) : (
        <>
        {incidences.length == 0 ? (
          <div style={{padding: '5px 20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography className={classes.subtitle2}>
              {t('TEXT.NO_INCIDENTS')}
            </Typography>
          </div>
        ) : (
          cycleJourneysIdList.map((cycleJourneyId, i) => {
            const relatedIncidences = incidences.filter(incidence => incidence.idCycleJourney === cycleJourneyId);
            
            if (relatedIncidences.length > 0) {
              return (
                <div key={i} style={{padding: '5px 20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Card className={classes.cardInverse}>
                    <Typography className={classes.subtitle2I}>
                      {t('CYCLE_TYPES.JOURNEY')}: {getNumberOfJourney(cycleJourneyId)}
                    </Typography>
                  </Card>
                  {relatedIncidences.map((incidence, index) => (
                    <Card
                      className={classes.cardSlim}
                      key={index}
                      onClick={() => handleIncidence(incidence.idIncident)}
                    >
                      <Typography className={classes.subtitle2}>
                        {incidence.participantName} {incidence.participantSurname}
                      </Typography>
                      <Typography p={1}>
                        {incidence.descriptionIncident}
                      </Typography>
                    </Card>
                  ))}
                </div>
              );
            }
    
            return null;
          })
        )}
      </>
    
    )}

      {/* <Grid item md={12} xs={12}> 
        <DynamicTable
          key={forceUpdate} // Cambia la clave para forzar una actualización cuando cambie
          columns={COLUMNS}
          endpoint='Incidents/List'
          isGlobalSearch={false}
          isColumnsSearch={false}
          isOrdered={true}
          columnSearchParam={columnSearch}
          pageSizeParam={10}
          orderColumn={ORDER}
          onListUpdate={handleForceUpdate} // Llama a esta función después de actualizar la lista
        />
      </Grid> */}
    </div>
  );
};

export default CycleIncidents;