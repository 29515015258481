const checkEventOverlap = (events) => {
    const result = {};

    // Ajustar eventos para que no cuenten solapamiento en el mismo minuto
    const adjustEventTimes = (events) => {
        events.sort((a, b) => new Date(a.eventDateFrom) - new Date(b.eventDateFrom));

        for (let i = 0; i < events.length - 1; i++) {
            const currentEvent = events[i];
            const nextEvent = events[i + 1];
            
            const currentEnd = new Date(currentEvent.eventDateTo);
            const nextStart = new Date(nextEvent.eventDateFrom);
            
            // Si el evento actual termina al mismo tiempo que el siguiente empieza, ajustamos
            if (currentEnd.getTime() === nextStart.getTime()) {
                // Ajustar el fin del evento actual para que termine un milisegundo antes
                currentEvent.eventDateTo = new Date(currentEnd.getTime() - 1);
            }
        }

        return events;
    };

    // Filtrar eventos que no son de la categoría 25
    let filteredEvents = events.filter(event => event.idCategory !== 25);

    // Agrupar eventos por día
    filteredEvents = adjustEventTimes(filteredEvents);
    filteredEvents.forEach(event => {
        const fromDate = new Date(event.eventDateFrom);
        const toDate = new Date(event.eventDateTo);

        const dayKey = `${('0' + fromDate.getDate()).slice(-2)}`;

        if (!result[dayKey]) {
            result[dayKey] = [];
        }

        result[dayKey].push({ from: fromDate, to: toDate });
    });

    // Contar solapamientos por día
    Object.keys(result).forEach(dayKey => {
        const dayEvents = result[dayKey];

        // Crear una línea de tiempo de eventos
        const timeline = [];

        // Agregar eventos de inicio y fin
        dayEvents.forEach(event => {
            timeline.push({ time: event.from, type: 'start' });
            timeline.push({ time: event.to, type: 'end' });
        });

        // Ordenar la línea de tiempo
        timeline.sort((a, b) => {
            if (a.time.getTime() === b.time.getTime()) {
                // Priorizar 'start' sobre 'end'
                return a.type === 'start' ? -1 : 1; 
            }
            return a.time - b.time;
        });

        // Contar el número de solapamientos
        let currentOverlaps = 0;
        let maxOverlaps = 0;

        timeline.forEach(event => {
            if (event.type === 'start') {
                currentOverlaps++;
            } else {
                if (currentOverlaps > 0) {
                    currentOverlaps--;
                }
            }

            maxOverlaps = Math.max(maxOverlaps, currentOverlaps);
        });

        result[dayKey] = maxOverlaps === 0 ? 1 : maxOverlaps; // Si no hay solapamientos, asignamos 1
    });

    // Obtener todos los días del mes
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Llenar el resultado para todos los días del mes
    for (let i = 1; i <= daysInMonth; i++) {
        const dayKey = `${('0' + i).slice(-2)}`;
        if (!result[dayKey]) {
            result[dayKey] = 1; // Día sin eventos
        }
    }

    return result;
};








export default checkEventOverlap;