import React, {useCallback, useEffect, useState} from 'react'
import Dialog from '@components/Dialog'
import FormActions from '@components/FormActions'
import HeaderTitle from '@components/HeaderTitle'
import Loading from '@components/Loading'
import {useDataCycleTypes} from '@hooks/useDataCycleTypes'
import {useForm} from '@hooks/useForm'
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Tab,
    Tabs,
    Typography
} from '@mui/material'
import {Assessment} from '@mui/icons-material'
import serviceEvaluatorInfo from '@services/serviceEvaluatorInfo'
import serviceConsultants from '@services/serviceConsultants'
import serviceCycles from '@services/serviceCycles'
import serviceCycleTypes from '@services/serviceCycleTypes'
import {useCommonStyles} from '@styles/common.style'
import {useLocation, useNavigate} from 'react-router-dom'
import TabConfiguration from './components/cycles/TabConfiguration'
import {useTranslation} from 'react-i18next'
import {snackActions} from '@helpers/snackbarUtils'
import TracingTable from './components/cycles/TracingTable'
import HistoryCycle from './components/cycles/HistoryCycle'
import serviceFiles from '@services/serviceFiles'
import serviceTimezones from '@services/serviceTimezones'

export function TabPanel(props) {
    const {children, value, index, ...other} = props
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: 0}}>{children}</Box>}
        </div>
    )
}
export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        key: index
    }
}

const CycleDetail = () => {
    const classes = useCommonStyles()
    const navigate = useNavigate()
    const [openDialog, setOpenDialog] = useState(false)
    const [editedJourneys, setEditedJourneys] = useState(false)
    const {t} = useTranslation()

    const [value, setValue] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingDelayRequest, setLoadingDelayRequest] = useState(false)
    const [loadingJourneys, setLoadingJourneys] = useState(false)
    const [cycleTypesList, setCycleTypesList] = useState([])
    const [evaluatorTypes, setEvaluatorTypes] = useState([])
    const [consultantsList, setConsultantsList] = useState([])
    const [evaluatorTypeList, setEvaluatorTypesList] = useState([])
    const [cycleJourneys, setCycleJourneys] = useState()
    const [timezonesList, setTimezonesList] = useState([]);
    const [cycleJourneysSIP, setCycleJourneysSIP] = useState([])
    const [startEv, setStartEv] = useState(false)
    const [disabledSaveButton, setDisabledSaveButton] = useState(false)
    const [hasChanged, setHasChanged] = useState(false)

    const lang = sessionStorage.getItem('lang') === 'es' ? 1 : sessionStorage.getItem('lang') === 'en' ? 2 : sessionStorage.getItem('lang') === 'it' ? 3 : 1;
    
    const {
        state: {data: CYCLE_DATA, type: TYPE}
    } = useLocation()

    const handleEditedJourneys = () => {
        !editedJourneys && setEditedJourneys(true)
    }

    const {formData, handleChange, setFormData} = useForm(
        CYCLE_DATA || {
            cycleJourneys: null,
            cycleCompanies: [],
            cycleInvitedGuests: [],
            cycleParticipants: []
        }
    )

    const HEADER_TITLE =
        TYPE === 'NEW' ? t('CYCLES.NEW_TITLE') : t('CYCLES.UPDATE_TITLE')
    const HEADER_SUBTITLE =
        TYPE === 'NEW' ? t('CYCLES.NEW_SUBTITLE') : t('CYCLES.UPDATE_SUBTITLE')

    const {
        emailList,
        allEmails,
        targetList,
        worksheetList,
        mobilesheetList,
        categories,
        showEmailItems
    } = useDataCycleTypes(TYPE, setFormData, formData)

    const hasEvaluator = formData?.evaluatorDefault !== null && formData?.evaluatorDefault !== 0;

    const getListValues = useCallback(async () => {
        const cycleTypesList = await fetchCycleTypes()
        const consultantsList = await fetchConsultants()
        const evaluatorTypesListTemp = await fetchEvaluatorTypes();
        const timezonesListtmp = await fetchTimezones();
        setEvaluatorTypesList(evaluatorTypesListTemp);
        setCycleTypesList(cycleTypesList)
        setConsultantsList(consultantsList)
        setTimezonesList(timezonesListtmp);
        if (TYPE === 'UPDATE' && hasEvaluator && formData.evaluatorTypes != null && formData.evaluatorTypes.length > 0) {
            const evaluatorTypes = await getEvaluatorTypes(evaluatorTypesListTemp);
            setFormData(prevState => {
                return {
                    ...prevState,
                    evaluatorTypes: evaluatorTypes
                }
            })
            setEvaluatorTypes(evaluatorTypes)
        }
        setLoading(false)
        setLoadingDelayRequest(false)
    }, [])

    useEffect(() => {
        let isCancelled = false
        if (!isCancelled) {
            getListValues()
        }
        return () => {
            isCancelled = true
        }
    }, [getListValues])

    useEffect(() => {
        if (formData?.cycleJourneys?.length !== 0) {
  
            const journeys = formData?.cycleJourneys?.filter(
                item => !item.cycleJourneyPrivate
            )
                    
            if (journeys !== cycleJourneys) {
                if (cycleJourneys !== undefined & cycleJourneys !== null) {
                    setCycleJourneys(cycleJourneys)
                } else {
                    setCycleJourneys(journeys)
                }
            }

            const journeysSIP = formData?.cycleJourneys?.filter(
                item => item.cycleJourneyPrivate
            )
            if (journeysSIP !== cycleJourneysSIP) {
                setCycleJourneysSIP(journeysSIP)
            }
            setEditedJourneys(false)
        }
    }, [formData.cycleJourneys])

    useEffect(() => {
        if (formData.evaluatorDefault != null && startEv) {
            setFormData(prevState => {
                const updatedParticipants = prevState.cycleParticipants.map((participant, i) => {
                    return {
                        ...participant,
                        idEvaluatorType: formData.evaluatorDefault
                    }
                })

                return {
                    ...prevState,
                    cycleParticipants: updatedParticipants
                };
            });
        }
        setStartEv(true)
    }, [formData.evaluatorDefault])

    const fetchCycleTypes = async () => {
        const {data} = await serviceCycleTypes.getCycleTypesList({
            //pageSize: 20,
            pageNumber: 0,
            globalSearch: '',
            search: [],
            order: [
                {
                    orderField: 'cycleTypeName',
                    orderAsc: true
                }
            ]
        })

        return data
    }

    const fetchEvaluatorTypes = async () => {
        const {data} = await serviceEvaluatorInfo.getTypes({
            pageNumber: 0,
            globalSearch: '',
            search: [],
            order: [
                {
                    orderField: 'evaluatorTypeName',
                    orderAsc: true
                }
            ]
        })
        return data
    }

    const fetchConsultants = async () => {
        const {data} = await serviceConsultants.getConsultantsList({
            pageSize: 50,
            pageNumber: 0,
            globalSearch: '',
            search: [
                {
                    searchField: 'consultantActive',
                    searchMode: 'equalTo',
                    searchValue: 1
                }
            ],
            order: [
                {
                    orderField: 'consultantName',
                    orderAsc: true
                }
            ]
        })

        return data
    }

    const fetchTimezones = async () => {
        const {data} = await serviceTimezones.getTimezonesByLanguage(lang)
        return data
    }

    const getEvaluatorTypes = (tempEvaluatorT) => {
        let tempEvaluatorTypes = [];
        const formDataEvaluatorTypes = formData.evaluatorTypes
        formDataEvaluatorTypes.forEach(evaluator => {
            // Verificar si idEvaluatorType ya existe en tempEvaluatorTypes
            const exists = tempEvaluatorTypes.some(item => item.idEvaluatorType === evaluator.idEvaluatorType);
            // Si no existe, buscar en evaluatorTypeList y agregarlo a tempEvaluatorTypes
            if (!exists) {
                const evaluatorType = tempEvaluatorT.find(item => item.idEvaluatorType === evaluator.idEvaluatorType);
                if (evaluatorType) {
                    tempEvaluatorTypes.push(evaluatorType);
                }
            }
        });
        return tempEvaluatorTypes;
    }

    const handleResponse = useCallback(
        async (response, actionType) => {
            if (!response.error) {
                if (actionType === 'DELETE') {
                    navigate(`/cycles`);
                } else {
                    const updatedId = actionType === 'NEW' ? response.data.idCycle : formData.idCycle;
                    const { data, error } = await serviceCycles.getCycleDetail(updatedId, true);
                    if (!error) {
                        setFormData(data);
                        navigate(`/cycleDetail`, {
                            state: { data, type: 'UPDATE' }
                        });
                    } 
                    setLoadingDelayRequest(false);
                }
            } else {
                setLoadingDelayRequest(false);
            }
        },
        [navigate, formData.idCycle]
    );

    const handleSubmit = useCallback(
        type => {
            //Se verifica si el usuario tiene permisos para realizar las acciones sobre un ciclo
            const userSession = JSON.parse(sessionStorage.getItem('userSession'))
            if (userSession.userIdsRoles.find(idRole => idRole == 1 || idRole == 4)) {
                const details = sessionStorage.getItem('details')
    
                let newValuesForm = {
                    ...formData,
                    // cycleImage: details?.detail?.cycleImage || null,
                    cycleImage: formData.cycleImage === '[NULL]' ? '[NULL]' : formData.cycleImage, // Si es [NULL], mantener [NULL]
                    cycleCompanies: formData?.cycleCompanies.map(
                        ({companyName, ...props}) => props
                    ),
                    cycleJourneys: cycleJourneys
                        ? [...cycleJourneys, ...cycleJourneysSIP]
                        : []
                }
               if (details !== null) {
                    newValuesForm = {
                        ...newValuesForm,
                        cycleImage: details === '[NULL]' ? '[NULL]' : details,
                    }
               }

                // console.log(newValuesForm);
                const missingFields = [];

                if (!newValuesForm?.idCompany) {
                    missingFields.push(t('LABEL.COMPANY'))
                }

                if (!newValuesForm?.idCycleType) {
                    missingFields.push(t('CYCLE_TYPES.CYCLE_TYPE'))
                }

                if (!newValuesForm?.cycleRemarks) {
                    missingFields.push(t('CYCLES.DESCRIPTION'))
                }

                if (!newValuesForm?.cycleName) {
                    missingFields.push(t('CYCLES.CYCLE_NAME'))
                }

                if (!newValuesForm?.cycleNameApp) {
                    missingFields.push(t('CYCLES.NAME_APP'))
                }

                if (!newValuesForm?.idCountry) {
                    missingFields.push(t('CYCLES.COUNTRY'))
                }

                if (!newValuesForm?.idLanguage) {
                    missingFields.push(t('CYCLES.LANG'))
                }

                if (newValuesForm?.hasEvaluator === true && !newValuesForm?.evaluatorDateInit) {
                    missingFields.push(t('LABEL.DATE_INIT_EVALUATOR'))
                }

                if (!newValuesForm?.cycleTimezone) {
                    missingFields.push(t('CYCLES.TIMEZONES'))
                }


                /* No s� c�mo a�adir que salga el error si no se pone fecha y hora de las Jornadas y el Evaluador.
                  if (!newValuesForm?.cycleJourneyDateFrom) {
                  missingFields.push(t('CYCLES.JOURNEYS.JOURNEY'))
                }
                
                if (!newValuesForm?.idConsultant) {
                  missingFields.push(t('CYCLES.JOURNEYS.CONSULTANT'))
                }*/

                if (type!=='DELETE' && missingFields.length > 0) {
                    const errorMessage = `${t('LOGIN.TAG_EMPTY_FIELDS')}: ${missingFields.join(', ')}`;
                    snackActions.error(errorMessage);
                } else {
                    setLoadingDelayRequest(true)
                    if (type == null) type = TYPE;
                    switch (type) {
                        case 'NEW':
                            return serviceCycles.newCycle(newValuesForm).then(response => handleResponse(response, 'NEW'))
                        case 'UPDATE':
                            return serviceCycles.updateCycle(newValuesForm).then(response => handleResponse(response, 'UPDATE'))
                        case 'DELETE':
                            return serviceCycles.deleteCycle(formData).then(response => handleResponse(response, 'DELETE'))
                        default:
                            setLoadingDelayRequest(false)
                    }
                }
            } else {
                snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'))
            }
        },
        [formData, handleResponse, cycleJourneys, cycleJourneysSIP]
    )

    const props = {
        formData,
        setFormData,
        handleChange,
        cycleTypesList,
        consultantsList,
        isUpdate: TYPE === 'UPDATE',
        hasEvaluator,
        loadingJourneys,
        setLoadingJourneys,
        setLoading,
        emailList,
        allEmails,
        targetList,
        worksheetList,
        mobilesheetList,
        categories,
        showEmailItems,
        evaluatorTypes,
        setEvaluatorTypes,
        // disabledSaveButton,
        cycleJourneys,
        setCycleJourneys,
        cycleJourneysSIP,
        setCycleJourneysSIP,
        handleEditedJourneys,
        evaluatorTypeList,
        startEv,
        setStartEv,
        timezonesList
    }

    if (loading) {
        return <Loading />
    }
    if (loadingDelayRequest) {
        return <Loading showMessageDelayRequest='true' />
    }
 
    return (
        <div className={classes.container}>
            {openDialog && (
                <Dialog
                    open={openDialog}
                    hasCloseBtn={false}
                    height={200}
                    title={t('CONFIRM.TITLE_CYCLE')}
                    subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
                    close={() => setOpenDialog(false)}
                    actions={
                        <Box display='flex' alignItems='center'>
                            <Box mr={1}>
                                <Button
                                    variant='contained'
                                    onClick={() => setOpenDialog(false)}
                                    color='error'
                                >
                                    {t('BUTTON.CANCEL')}
                                </Button>
                            </Box>
                            <Button
                                variant='contained'
                                onClick={() => handleSubmit('DELETE')}
                            >
                                {t('BUTTON.DELETE')}
                            </Button>
                        </Box>
                    }
                />
            )}

            <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
                <Box display='flex' flex={1}>
                    <Box
                        flex={1}
                        justifyContent='center'
                        display='flex'
                        alignItems='center'
                        flexDirection='column'
                    >
                    <Box display="flex" justifyContent="center" alignItems="center">
                        {formData.cycleKeyFirst && formData.cycleKeySecond ? (
                            <>
                                <Typography variant='button' fontSize={14}>
                                    {t('CYCLES.CODE')}
                                    &nbsp;&nbsp;
                                </Typography>
                                <Typography variant='button' fontSize={20}>
                                    {formData.cycleKeyFirst + ' ' + formData.cycleKeySecond}
                                </Typography>
                            </>
                        ) : (
                            <Typography />
                        )}
                        <Tooltip title={t('MENU_ADMIN.COMPLETE_REPORT')}>
                            <IconButton
                                aria-label='info'
                                color='primary'
                                style={{
                                    height: 10,

                                    float: 'right'
                                }}
                                onClick={() => {
                                    serviceFiles.getExcelParticipants(formData.idCycle)
                                }}
                            >
                                <Assessment fontSize='inherit' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography 
                        variant='body2' 
                        fontSize={25} 
                        style={{ color: '#0085b4', marginTop: '-8px' }}
                    >
                        {formData.cycleName}
                    </Typography>
                    </Box>
                    <Box>
                        <FormActions
                            show={TYPE === 'UPDATE'}
                            handleDelete={() => setOpenDialog(true)}
                            handleSave={() => {
                                handleSubmit(TYPE)
                            }}
                            disabledButton={disabledSaveButton}
                            urlBack='/cycles'
                        />
                    </Box>
                </Box>
            </HeaderTitle>

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={value}
                    onChange={(_, value) => setValue(value)}
                    aria-label='basic tabs example'
                >
                    {[
                        t('CYCLES.CONFIGURATION'),
                        TYPE !== 'NEW' && t('CYCLES.TRACING'),
                        TYPE !== 'NEW' && t('CYCLES.HISTORICAL')
                    ].map((label, i) => {
                        if (label.length > 1) {
                            return <Tab label={label} {...a11yProps(`cycleTab${i}`)} />
                        }
                    })}
                </Tabs>
            </Box>

            <TabPanel value={value} index={0} key={`cycleTabPanel${0}`}>
                <TabConfiguration {...props} />
            </TabPanel>

            <TabPanel value={value} index={1} key={`cycleTabPanel${1}`}>
                <TracingTable evaluatorTypeList={evaluatorTypeList} setFormData={setFormData} formData={formData} />
            </TabPanel>

            <TabPanel value={value} index={2} key={`cycleTabPanel${2}`}>
                <HistoryCycle cycle={formData.idCycle} />
            </TabPanel>
        </div>
    )
}

export default CycleDetail
