import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './layout/Header'
import Footer from './layout/Footer'
import { Grid } from '@mui/material'
import serviceCycles from '@services/serviceCycles'
import serviceCycleParticipants from '@services/serviceCycleParticipants'
import serviceCycleJourneys from '@services/serviceCycleJourneys'
import { useOWStyles } from '@styles/ottowalter.style'
import { useLocation } from 'react-router-dom'
import { useStyles } from '@styles/participants.style'
import Loading from '@components/Loading'

const Home = () => {
  const owclass = useOWStyles()
  const [cycle, setCycle] = useState(null)
  const location = useLocation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [displaySaw, setDisplaySaw] = useState('block')

  useEffect(() => {
    setLoading(true)
    serviceCycleParticipants.getCycleActive().then(response => {
      if (!response.error) {
        if (null != response.data) {
          let idCycle = response.data.idCycle
          serviceCycles.getCycleDetail(idCycle, true).then(response => {
            if (!response.error) {
              serviceCycleJourneys
                .getCycleJourneyByParticipant(0)
                .then(response2 => {
                  if (!response2.error) {
                    // console.log(response2.data)
                    if (response2.data != null) {
                      response.data.cycleJourneys.push(response2.data)
                    }
                    // console.log(response.data)
                    setCycle(response.data)
                    setLoading(false)
                  }
                })
            }
          })
        }
      }
    })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.participants}>
      <div
        className={classes.card}
        style={{ padding: '0px', minHeight: '100% !important' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ 
              height: cycle?.cycleImage !== '' && cycle?.cycleImage !== null ? '145px' : '120px', 
              display: 'flex', 
              alignItems: 'center' 
            }}
            mb={2}
          >
            <Header groupPhoto={cycle?.cycleImage} />
          </Grid>
          <Grid
            className={owclass.outletZone}
            style={{ padding: '0px' }}
            item
            xs={12}
          >
            {cycle && (
              <Outlet
                context={{
                  cycle: [cycle, setCycle],
                  displaySaw: [displaySaw, setDisplaySaw]
                }}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display={displaySaw}
            style={{
              zIndex: '199',
              height: '60px',
              width: '100%',
              position: 'fixed',
              bottom: '0px',
              maxWidth: '450px'
            }}
          >
            <Footer idCycle={cycle?.idCycle} />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Home
